import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ConstellationPageTemplate from "../constellation/constellation";

const ConstellationGroupPage = ({ data, location }) => {
  const showAnimation = location?.state?.fromClick;

  return (
    <ConstellationPageTemplate
      activeGroup={{
        applyTransition: location?.state?.applyTransition,
        showAnimation: showAnimation,
        ...data?.craftAPI?.entry,
      }}
    />
  );
};

export const constellationQuery = graphql`
  query ($uri: [String], $section: [String] = "constellations") {
    craftAPI {
      entry(uri: $uri) {
        ... on CraftAPI_constellations_constellationGroup_Entry {
          id
          parent {
            uri
          }
          uri
          title
          descriptionHeader
          tag(label: true)
          objects: children {
            ...ConstellationChildObjectsFragment
          }
          constellationShortDescription
          constellationText
          date
          constellationImageFull: constellationImage {
            ...ImageMetadataFragment
          }
          btnText
          reference {
            title
            uri
          }
        }
        ...DirectionNavigationFragment
      }
    }
  }
`;

ConstellationGroupPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      applyTransition: PropTypes.bool,
      fromClick: PropTypes.bool,
    }),
  }),
};

export default ConstellationGroupPage;
