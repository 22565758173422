import { NMAAHCPropTypes } from "assets";
import { graphql } from "gatsby";
import {
  ConstellationPage,
  convertConstellationGroup,
  convertConstellationObject,
} from "organisms";
import PropTypes from "prop-types";
import * as React from "react";

const ConstellationPageTemplate = ({ activeGroup, activeObject, data }) => {
  if (activeObject)
    return convertConstellationObject({
      ...activeObject,
      isChildObject: true,
    });

  if (activeGroup)
    return convertConstellationGroup({
      blurred: false,
      ...activeGroup,
    });

  return (
    <ConstellationPage
      coverImage={data?.craftAPI?.entry.coverImage}
      description={data?.craftAPI?.entry.shortDescription}
      groups={data?.craftAPI?.entry.children}
      subtitle={data?.craftAPI?.entry.subtitle}
      timelineMode={data?.craftAPI?.entry.timelineMode}
      title={data?.craftAPI?.entry.title}
    />
  );
};

ConstellationPageTemplate.propTypes = {
  activeGroup: PropTypes.shape({}),
  activeObject: PropTypes.shape({}),
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        children: PropTypes.shape({}),
        coverImage: NMAAHCPropTypes.Image,
        shortDescription: PropTypes.string,
        subtitle: PropTypes.string,
        timelineMode: PropTypes.bool,
        title: PropTypes.string,
      }),
    }),
  }),
};

export const constellationQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        ... on CraftAPI_constellations_constellation_Entry {
          id
          title
          subtitle
          shortDescription
          coverImage {
            ... on CraftAPI_image_Asset {
              altText
              url(transform: "constellationModalIcon")
              imageFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          children(type: "constellationGroup") {
            ... on CraftAPI_constellations_constellationGroup_Entry {
              id
              title
              date
              uri
              tag(label: true)
              constellationImage {
                ... on CraftAPI_image_Asset {
                  ...ImageMetadataOnlyFragment
                  url(transform: "constellation")
                  imageFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ConstellationPageTemplate;
